<template>
  <nav aria-label="Footer navigation">
    <ul class="reset-list footer-navigation">
      <li><a href="/terms-of-service">Terms of service</a></li>
      <li><a href="/privacy">Privacy policy</a></li>
      <li><a href="/about">About</a></li>
    </ul>
  </nav>
</template>

<style scoped>
.footer-navigation {
  display: flex;
  gap: var(--layout-base-margin);
}

.footer-navigation a:hover,
.footer-navigation a:focus {
  text-decoration: none;
}
</style>
