<template>
  <footer class="app-footer">
    <AppLogo iconOnly="true" />
    <AppFooterNavigation />
  </footer>
</template>

<style scoped>
.app-footer {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 0 0 20%;
  justify-content: space-between;
  padding: 0.5rem var(--layout-base-spacing);
}
</style>
