<template>
  <header class="app-header">
    <app-logo />
    <client-only>
      <app-navigation />
    </client-only>
  </header>
</template>

<style scoped>
.app-header {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex: 0 0 20%;
  justify-content: space-between;
  padding: 0.5rem var(--layout-base-spacing);
}
</style>
