<template>
  <div class="loading" v-if="isLoading">
    <base-spinner highlightColor="#fff" size="1.5rem" />
  </div>

  <nav aria-label="Main navigation" v-else-if="!isLoading && !isAuthenticated">
    <ul id="main-navigation" class="reset-list main-navigation">
      <li>
        <a href="/login" @click.prevent="handleLogin">Login</a>
      </li>
      <li>
        <a href="/signup" @click.prevent="handleSignup">Sign Up</a>
      </li>
    </ul>
  </nav>

  <nav aria-label="Main navigation" v-else-if="!isLoading && isAuthenticated">
    <the-authenticated-user-navigation />
  </nav>
</template>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

function handleLogin() {
  loginWithRedirect({
    appState: {
      target: "/",
    },
  });
}

function handleSignup() {
  loginWithRedirect({
    appState: {
      target: "/",
    },
    authorizationParams: {
      screen_hint: "signup",
    },
  });
}
</script>

<style scoped>
.main-navigation {
  gap: 1rem;
}

.main-navigation,
.main-navigation a {
  align-items: center;
  display: flex;
}
.main-navigation a:link {
  font-size: var(--typography-medium);
  text-decoration: none;
}

.main-navigation a:hover,
.main-navigation a:focus {
  text-decoration: underline;
}
</style>
