<template>
  <ul class="reset-list main-nav-container">
    <li class="main-nav-entry">
      <button
        :aria-expanded="showMenu"
        aria-controls="dropdown-list-main"
        class="ghost-button dropdown-trigger"
        type="button"
        @click="showMenu = !showMenu"
      >
        <base-avatar />
      </button>
      <ul
        id="dropdown-list-main"
        class="reset-list dropdown-list"
        :class="showMenu && 'show'"
      >
        <li
          v-for="entry in userNavigation"
          :key="entry.name.split(' ').join('-').toLowerCase()"
        >
          <nuxt-link class="dropdown-list-item" :href="entry.path">{{
            entry.name
          }}</nuxt-link>
        </li>
        <li>
          <base-button
            buttonStyle="link"
            class="dropdown-list-item"
            isGhost
            @click="handleLogout"
            >Logout</base-button
          >
        </li>
      </ul>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useAuth0 } from "@auth0/auth0-vue";

import { useUserStateStore } from "@/stores/UserStateStore";

const { logout } = useAuth0();
const showMenu: Ref<boolean> = ref(false);

type BaseNavigation = {
  name: string;
  path?: string;
};

const baseNavigation = [
  {
    name: "Edit profile",
    path: "/profile/edit",
  },
];

const userSessionStateStore = useUserStateStore();

const { userState } = storeToRefs(userSessionStateStore);

const userRoleInitialState: Ref<string | undefined> = ref(
  userState.value?.role
);
const isVerifiedInitialState: Ref<boolean | undefined> = ref(
  userState.value?.isVerified
);
const userNavigation: Ref<BaseNavigation[]> = ref(baseNavigation);

// A user can only add students once their account is verified.
// There is no reason to show these menu entry in other cases.
if (isVerifiedInitialState.value) {
  userNavigation.value.push({
    name: "View students",
    path: "/students",
  });
}

if (userRoleInitialState.value === UserRole.ADMIN) {
  userNavigation.value.push({
    name: "Admin",
    path: "/admin",
  });
}

userSessionStateStore.$subscribe((_mutation, state) => {
  // We know that the state was mutated so let's get the role
  // and verified status from the mutated state.
  const mutatedStateRole = state.userState?.role;
  const mutatedSVerifiedState = state.userState?.isVerified;

  // If our initial role is not the same as the mutated role, then
  // let's determine whether the new role is admin and update the
  // navigation accordingly.
  if (
    userRoleInitialState.value !== mutatedStateRole &&
    state.userState?.role === UserRole.ADMIN
  ) {
    userNavigation.value.push({
      name: "Admin",
      path: "/admin",
    });
  }

  // If the verified status has changed from the initial state, then
  // let's determine whether the new status is verified and update the
  // navigation accordingly.
  if (
    mutatedSVerifiedState !== isVerifiedInitialState.value &&
    isVerifiedInitialState.value
  ) {
    userNavigation.value.push({
      name: "View students",
      path: "/students",
    });
  }
});

function handleLogout() {
  userSessionStateStore.$reset();
  logout({ logoutParams: { returnTo: window.location.origin } });
}

document.addEventListener("keyup", (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    showMenu.value = false;
  }
});

document.addEventListener("click", (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  const isAvatar = target.classList.contains("avatar");
  const isDropdownTrigger = target.classList.contains("dropdown-trigger");
  const isDropdownList = target.classList.contains("dropdown-list");

  if (!isAvatar && !isDropdownTrigger && !isDropdownList) {
    showMenu.value = false;
  }
});
</script>

<style scoped>
.dropdown-trigger {
  cursor: pointer;
  display: block;
}

.dropdown-trigger:focus .avatar,
.dropdown-trigger:hover .avatar {
  border: 4px solid var(--color-blue-60);
  box-shadow: var(--box-shadow-subtle);
}

.dropdown-trigger:focus .avatar.fallback,
.dropdown-trigger:hover .avatar.fallback {
  border: 0;
}

.main-nav-container {
  display: flex;
  gap: var(--layout-narrow-spacing);
}

.main-nav-entry {
  position: relative;
}

.dropdown-list {
  display: none;
}

.dropdown-list .dropdown-list-item {
  color: var(--color-link-color);
  display: block;
  min-width: 100%;
  padding: var(--layout-base-padding);
  text-decoration: none;
}

.dropdown-list li:first-child .dropdown-list-item {
  border-top-left-radius: var(--border-radius-dropdown-list);
  border-top-right-radius: var(--border-radius-dropdown-list);
}

.dropdown-list li:last-child .dropdown-list-item {
  border-bottom-left-radius: var(--border-radius-dropdown-list);
  border-bottom-right-radius: var(--border-radius-dropdown-list);
}

.dropdown-list .dropdown-list-item:hover,
.dropdown-list .dropdown-list-item:focus {
  background-color: var(--color-blue-60);
  color: var(--color-neutral-inverted);
}

.show {
  background-color: var(--color-neutral-inverted);
  border: 1px solid var(--color-blue-60);
  border-radius: var(--border-radius-dropdown-list);
  box-shadow: var(--box-shadow-subtle);
  display: block;
  /* padding: var(--layout-base-spacing) 0; */
  position: absolute;
  right: 0.5rem;
  top: 4.5rem;
  width: max-content;
}
</style>
